import { PATH } from '@/constants';
import { Badge, BadgeProps } from '@shopify/polaris';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

const CustomBadge = (props: BadgeProps) => {
  const navigate = useNavigate();
  return (
    <div className="pointer" onClick={() => navigate(PATH.PRICING_PLAN)}>
      <Badge tone={props.tone}>{props.children}</Badge>
    </div>
  );
};
export default memo(CustomBadge);
