import { Badge, Card, Tabs } from '@shopify/polaris';
import { memo, useCallback, useState } from 'react';
import VisitorAnalyticsChart from './VisitorAnalytics';
import BlockedChart from './Blocked';
import userPlans from '@/hooks/userPlans';
import { useSelector } from 'react-redux';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';

const AnalyticsChart = () => {
  const { userPlanFree } = userPlans();
  const dataSettings = useSelector(dataSettingsSelector);
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => setSelected(selectedTabIndex), []);
  const tabs = [
    {
      id: 'visited',
      content: 'Allowed visitor',
      component: <VisitorAnalyticsChart />,
    },
    {
      id: 'Blocked IPs',
      content: 'Blocked visitor',
      component: <BlockedChart />,
    },
  ];
  return (
    <Card padding={'100'}>
      <div className='card-chart-container'>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <div className="chart-container">
            {tabs[selected].component}
            {
              userPlanFree && !dataSettings?.settings.enableVisitorAnalytics &&
              <div className='badge-demo'>
                <Badge tone='magic'>Demo</Badge>
              </div>
            }
          </div>
        </Tabs>
      </div>
    </Card>
  );
};
export default memo(AnalyticsChart);
