import BannerGrantPermission from '@/components/BannerGrantPermission';
import CriticalBanner from '@/components/CriticalBanner';
import CustomButton from '@/components/CustomButton';
import InformationBanner from '@/components/InformationBanner';
import RegularText from '@/components/RegularText';
import CustomCard from '@/components/customCard';
import Layout from '@/components/layout';
import { config } from '@/config';
import { PATH } from '@/constants';
import { BlockCheckoutType, ScopeGroups, SortType, SubKeyHelpCenter, UserPlan } from '@/constants/enum';
import { checkShowErrorInline, disablePlan, handleToastMutation } from '@/helpers';
import useScope from '@/hooks/Scope';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import bannerSlice, {
  abandonedCheckoutSelector,
  bannerBlockCheckoutAccessSelector,
  bannerUpgradeShopifyPlusSelector,
} from '@/redux/slice/banner.slice';
import blockCheckoutSlice, { dataTableSelector, inputSearchSelector, tableTabsSelector } from '@/redux/slice/blockCheckout.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { IParamsApi } from '@/types/api/params.api';
import { IResponseApi } from '@/types/api/response.api';
import {
  Button,
  Card,
  Checkbox,
  IndexFilters,
  IndexFiltersMode,
  Link,
  Modal,
  SortButtonChoice,
  TextField,
  Tooltip,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import { DeleteIcon, RefreshIcon } from '@shopify/polaris-icons';
import _debounce from 'lodash/debounce';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Tabs from './Components/Tabs';
import Table from './Table';
import { BlockCheckoutFilter } from './Table/filter';
import { BlockCheckoutStyled } from './styled';
// import TableBlackList from './BlockCheckoutAccess/Table/TableBlackList';
import BannerReviewApp from '@/components/BannerReviewApp';
import HelpCenter from '@/components/HelpCenter';
import blockCheckoutAccessSlice, {
  blackListTableSelector,
  inputSearchBlockAccessSelector,
} from '@/redux/slice/blockCheckoutAccess';
import helpCenterSlice, { filterSubKeyHelpCenterWhenEditBlockCheckout, subKeySelector } from '@/redux/slice/helpCenter.slice';
import { BlackListFilter } from './BlockCheckoutAccess/Table/Filters/Filter.blacklist';
import TableBlackList from './BlockCheckoutAccess/Table/TableBlackList';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';

const sortOptions: SortButtonChoice[] = [
  { label: 'Created time', value: 'createdAt asc', directionLabel: 'Asconending' },
  { label: 'Created time', value: 'createdAt desc', directionLabel: 'Descending' },
];

const BlockCheckout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableTabs = useSelector(tableTabsSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const scope = useScope();
  const dataSettings = useSelector(dataSettingsSelector);
  const abandonCheckoutInfo = useSelector(abandonedCheckoutSelector);
  const bannerUpgradeShopifyPlus = useSelector(bannerUpgradeShopifyPlusSelector);
  const inputSearchBlockFields = useSelector(inputSearchSelector);
  const inputSearchBlockAccess = useSelector(inputSearchBlockAccessSelector);
  const dataTableBlockFields = useSelector(dataTableSelector);
  const dataTableBlockAccess = useSelector(blackListTableSelector);
  const { userPlanFree, userPlanPremium, shopifyPlanPlus, userPlanShopifyPlus, userPlanEnterprise } = userPlans();
  const contentTooltip = userPlanFree && shopifyPlanPlus ? 'Available on Shopify Plus plan' : 'Available on Enterprise plan';
  const { mode, setMode } = useSetIndexFiltersMode();
  const { data } = apiCaller.useFetchBlockCheckoutQuery(config.shop, { skip: isSkip });
  const [trigger, updateStatus] = apiCaller.useUpdateBlockCheckoutMutation();
  const [deleteAllBlockCheckout, deleteAllBlockCheckoutStatus] = apiCaller.useDeleteAllBlockCheckoutRulesMutation();
  const [deleteAllBlockAccess, deleteAllBlockAccessStatus] = apiCaller.useDeleteAllBlackListSettingMutation();
  const [onGuide, setOnGuild] = useState(false);
  const bannerBlockCheckoutAccess = useSelector(bannerBlockCheckoutAccessSelector);
  const subKeyHelpCenter = useSelector(subKeySelector);
  const isShopifyPlus = process.env.REACT_APP_ENV === 'production' ? shopifyPlanPlus : true;
  const [autoSync, autoSyncStatus] = apiCaller.useAutoSyncRuleToBlockCheckoutAccessMutation();
  const [errorBanner, setErrorBanner] = useState({
    status: false,
    msg: '',
  });
  const [stateBackup, setStateBackup] = useState({
    feature: false,
    bot: false,
    message: 'Order cannot be placed due to suspected fraud.',
  });
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [state, setState] = useState({
    feature: false,
    bot: false,
    message: 'Order cannot be placed due to suspected fraud.',
    error: ""
  });

  const handleOpenModalDeleteAll = () => {
    setIsOpenModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleDeleteAllBlockCheckout = useCallback(() => {
    if (tableTabs) {
      deleteAllBlockCheckout().then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
          handleCloseModalDelete();
        }
      });
    } else {
      deleteAllBlockAccess({ pathName: 'checkout-page' }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
          handleCloseModalDelete();
        }
      });
    }
  }, [deleteAllBlockAccess, deleteAllBlockCheckout, dispatch, tableTabs]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    _debounce((value: IParamsApi.IGetListCheckout | IParamsApi.IGetSettingList) => {
      if (tableTabs) {
        dispatch(blockCheckoutSlice.actions.handleDataTable(value));
      } else {
        if ('priority' in value) dispatch(blockCheckoutAccessSlice.actions.handleBlackListTable(value));
      }
    }, 1000),
    [],
  );

  const handleInputChangeTable = useCallback(
    (search: string) => {
      if (tableTabs) {
        dispatch(blockCheckoutSlice.actions.handleInputSearch(search));
        debounceSearch({
          ...dataTableBlockFields,
          search,
          page: search ? 1 : dataTableBlockFields.page,
        });
      } else {
        dispatch(blockCheckoutAccessSlice.actions.handleInputSearch(search));
        debounceSearch({
          ...dataTableBlockAccess,
          search,
          page: search ? 1 : dataTableBlockAccess.page,
        });
      }
    },
    [dataTableBlockAccess, dataTableBlockFields, debounceSearch, dispatch, tableTabs],
  );

  const handleClearAllFilter = useCallback(() => {
    if (tableTabs) {
      dispatch(
        blockCheckoutSlice.actions.handleDataTable({
          ...dataTableBlockFields,
          perPage: '10',
          criteria: '',
        }),
      );
    } else {
      dispatch(
        blockCheckoutAccessSlice.actions.handleBlackListTable({
          ...dataTableBlockAccess,
          perPage: '10',
          criteria: '',
          type: '',
        }),
      );
    }
  }, [dataTableBlockAccess, dataTableBlockFields, dispatch, tableTabs]);

  const handleOpenLearnMore = () => {
    window.open('https://docs.ipblocker.io/getting-started/block-checkout/auto-block-checkout-with-free-products', '_blank');
  };
  const handleOpenLearnMoreBanner = () => {
    window.open('https://docs.ipblocker.io/getting-started/block-checkout', '_blank');
  };

  const handleUpdateGeneral = () => {
    if (state.message.trim().length === 0) {
      setState((pre) => ({
        ...pre,
        error: "Error message cannot be blank"
      }))
    } else {
      trigger({
        enable: state.feature,
        enableBlockBot: state.bot,
        message: state.message.trim(),
      }).then((res) => {
        const condition = checkShowErrorInline(res);
        if (condition.status) {
          setErrorBanner(condition);
        } else {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
    }
  };

  const tabsConfig = [
    {
      id: 'block-checkout-access',
      content: 'Block checkout access',
      panelID: 'block-checkout-access',
      disabled: !isShopifyPlus,
      children: (
        <TableBlackList
          onGuide={() => {
            setOnGuild(true);
          }}
        />
      ),
    },

    {
      id: 'block-checkout-fields',
      panelID: 'block-checkout-fields',
      content: 'Block checkout fields',
      children: (
        <div>
          <div className="mt-8">
            <InformationBanner
              isVisible={abandonCheckoutInfo}
              onDismiss={() => dispatch(bannerSlice.actions.handleBannerAbandonedCheckout(false))}
            >
              Due to Shopify's regulations, third parties are not allowed to intervene in abandoned checkouts, so this feature
              cannot block abandoned checkouts.
            </InformationBanner>
          </div>
          <Table
            onEdit={(item: IResponseApi.IBlockCheckoutRule) => {
              const subKey = filterSubKeyHelpCenterWhenEditBlockCheckout.find((filter) =>
                filter.criteria.includes(item.criteria as BlockCheckoutType),
              )?.value;
              if (subKey) {
                dispatch(helpCenterSlice.actions.handleSubKey(subKey));
              }
              dispatch(blockCheckoutSlice.actions.handleBlockRuleSelected(item));
              dispatch(blockCheckoutSlice.actions.handleBlockRuleBackupSelected(item));
              navigate(PATH.BLOCK_CHECKOUT_PAGE);
            }}
            onGuide={() => {
              setOnGuild(true);
            }}
          />
        </div>
      ),
    },
  ].filter((item) => !!item);

  const handleSync = () => {
    autoSync().then((res) => {
      dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
    });
  };

  useEffect(() => {
    if (data?.data) {
      setState({
        bot: data.data.enableBlockBot,
        message: data.data.message,
        feature: data.data.enable,
        error: ""
      });
      setStateBackup({
        bot: data.data.enableBlockBot,
        message: data.data.message,
        feature: data.data.enable,
      });
    }
  }, [data?.data]);

  useEffect(() => {
    if (subKeyHelpCenter !== SubKeyHelpCenter.BlockCheckoutGeneral) {
      dispatch(helpCenterSlice.actions.handleSubKey(SubKeyHelpCenter.BlockCheckoutGeneral));
    }
  }, [dispatch, subKeyHelpCenter]);

  return (
    <Layout
      layoutProps={{
        title: 'Block checkout',
        primaryAction: <HelpCenter />,
      }}
    >
      {/* {!userPlanFree ? <CheckoutRulesBanner marginBottom={'12px'} /> : null} */}

      <BlockCheckoutStyled isBlockAccess={!tableTabs}>
        {onGuide ? <div className="wrapper-guide" onClick={() => setOnGuild(false)} /> : null}
        {userPlanShopifyPlus && (
          <InformationBanner
            hideIcon
            isVisible={bannerBlockCheckoutAccess && isShopifyPlus}
            onDismiss={() => {
              dispatch(bannerSlice.actions.handleBannerWhiteListBlockCheckoutAccess(false));
            }}
            title="✨ More protection with Blockify's Two-Factor Authentication (2FA), available for Shopify Plus only."
          >
            <RegularText>
              Blockify highly recommends that Shopify Plus stores use 2FA to enhance overall store security. For a step-by-step
              guide on setting up rules with app block, please read&nbsp;
              <span className="link-no-monochrome">
                <Link
                  url="https://docs.ipblocker.io/~/changes/44Zuc8j7J1SjykZJzwQn/getting-started/instruction-to-add-checkout-blocks-for-shopify-plus-only"
                  target="_blank"
                >
                  this article
                </Link>
              </span>
              .
            </RegularText>
          </InformationBanner>
        )}
        <BannerGrantPermission
          title="Important step to use this feature"
          isVisible={
            !dataSettings?.settings.user.validationScope &&
            (dataSettings?.settings.user.plan === UserPlan.ENTERPRISE ||
              dataSettings?.settings.user.plan === UserPlan.SHOPIFYPLUS)
          }
          enum={ScopeGroups.validation}
          children="To use this feature, we need permission to manage your checkout rule settings."
          variant="primary"
        />

        <BannerReviewApp />

        {!userPlanShopifyPlus && !userPlanEnterprise && (
          <InformationBanner
            title={
              shopifyPlanPlus
                ? 'Block Checkout feature is available for Shopify Plus plan only.'
                : 'Block Checkout feature is available for Enterprise plan only'
            }
            isVisible={bannerUpgradeShopifyPlus}
            onDismiss={() => {
              dispatch(bannerSlice.actions.handleBannerUpgradeShopifyPlus(false));
            }}
          >
            <RegularText>
              Prevent all unexpected orders on the checkout page based on specific conditions you configure with the Block
              Checkout feature. <Link onClick={handleOpenLearnMoreBanner}>Learn more</Link>
            </RegularText>
            <div className="mt-16 ">
              <Button
                onClick={() => {
                  navigate(PATH.PRICING_PLAN);
                }}
                variant="primary"
              >
                Upgrade Now
              </Button>
            </div>
          </InformationBanner>
        )}

        {userPlanFree || userPlanPremium ? (
          <Tooltip content={contentTooltip}>
            <div className={disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) ? 'div-disable' : ''}>
              <CustomCard title={'Quick Setup'}>
                <CriticalBanner isVisible={errorBanner.status}>
                  <RegularText>{errorBanner.msg}</RegularText>
                </CriticalBanner>
                <div className="mt-16">
                  <Checkbox
                    label={
                      <RegularText>
                        Auto-block creating orders with a subtotal of 0 and matching last name with first name.{' '}
                        <Link onClick={handleOpenLearnMore}>Learn more</Link>
                      </RegularText>
                    }
                    checked={state.bot}
                    onChange={() => { }}
                  />
                </div>
                <div className="mt-8">
                  <TextField
                    autoComplete="off"
                    label="Error message"
                    helpText="This message will be displayed on your customer's checkout page when their information meets the rule you've configured to block."
                    value={state.message}
                    onChange={() => { }}
                  />
                </div>
                <div className="mt-16 d-flex flex-end">
                  <Button
                    variant="primary"
                    onClick={() => { }}
                    loading={updateStatus.isLoading}
                    disabled={JSON.stringify(stateBackup) === JSON.stringify(state) || scope.isViewOnly}
                  >
                    Save
                  </Button>
                </div>
              </CustomCard>
            </div>
          </Tooltip>
        ) : (
          <div>
            <CustomCard title={'Quick Setup'}>
              <CriticalBanner isVisible={errorBanner.status}>
                <RegularText>{errorBanner.msg}</RegularText>
              </CriticalBanner>
              <div className="mt-16">
                <Checkbox
                  label={
                    <RegularText>
                      Auto-block creating orders with a subtotal of 0 and matching last name with first name.{' '}
                      <Link onClick={handleOpenLearnMore}>Learn more</Link>
                    </RegularText>
                  }
                  checked={state.bot}
                  onChange={() =>
                    setState({
                      ...state,
                      bot: !state.bot,
                    })
                  }
                />
              </div>
              <div className="mt-8">
                <TextField
                  autoComplete="off"
                  label="Error message"
                  helpText="This message will be displayed on your customer's checkout page when their information meets the rule you've configured to block."
                  value={state.message}
                  onChange={(value) => {
                    setState({
                      ...state,
                      message: value,
                      error: ""
                    });
                  }}
                  error={state.error}
                />
              </div>
              <div className="mt-16 d-flex flex-end">
                <Button
                  variant="primary"
                  onClick={handleUpdateGeneral}
                  loading={updateStatus.isLoading}
                  disabled={JSON.stringify(stateBackup) === JSON.stringify(state) || scope.isViewOnly}
                >
                  Save
                </Button>
              </div>
            </CustomCard>
          </div>
        )}

        {userPlanFree || userPlanPremium ? (
          <Tooltip content={contentTooltip}>
            <div className={disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) ? 'div-disable' : ''}>
              <div className="mt-16 control-rule-container">
                <Tabs
                  onCloseGuide={() => {
                    setOnGuild(false);
                  }}
                />
              </div>
            </div>
          </Tooltip>
        ) : (
          <div className="mt-16 control-rule-container">
            <Tabs
              onCloseGuide={() => {
                setOnGuild(false);
              }}
            />
          </div>
        )}

        {userPlanFree || userPlanPremium ? (
          <Tooltip content={contentTooltip} zIndexOverride={600}>
            <div className={disablePlan([UserPlan.FREE, UserPlan.PREMIUM]) ? 'div-disable' : ''}>
              <div className="mt-16">
                <Card padding={'0'}>
                  <IndexFilters
                    queryValue={tableTabs ? inputSearchBlockFields : inputSearchBlockAccess}
                    queryPlaceholder={
                      tableTabs
                        ? 'Searching by email, phone number, name, country, address'
                        : 'Searching by IP, country, state/province, internet provider'
                    }
                    onQueryChange={handleInputChangeTable}
                    onQueryClear={() => {
                      handleInputChangeTable('');
                    }}
                    tabs={tabsConfig}
                    selected={tableTabs}
                    sortOptions={sortOptions}
                    sortSelected={
                      tableTabs
                        ? [`${dataTableBlockFields.sortBy} ${dataTableBlockFields.sort.toLowerCase()}`]
                        : [`${dataTableBlockAccess.sortBy} ${dataTableBlockAccess.sort.toLowerCase()}`]
                    }
                    onSelect={(index) => {
                      dispatch(blockCheckoutSlice.actions.handleTableTabs(index));
                    }}
                    canCreateNewView={false}
                    filters={tableTabs ? BlockCheckoutFilter().filters : BlackListFilter().filters}
                    appliedFilters={tableTabs ? BlockCheckoutFilter().appliedFilters : BlackListFilter().appliedFilters}
                    onClearAll={handleClearAllFilter}
                    cancelAction={{ onAction: () => { } }}
                    mode={mode}
                    setMode={setMode}
                    onSort={(value: string[]) => {
                      const data = value[0].split(' ');
                      if (tableTabs) {
                        dispatch(
                          blockCheckoutSlice.actions.handleDataTable({
                            ...dataTableBlockFields,
                            sortBy: data[0],
                            sort: data[1] as SortType,
                          }),
                        );
                      } else {
                        dispatch(
                          blockCheckoutAccessSlice.actions.handleBlackListTable({
                            ...dataTableBlockAccess,
                            sortBy: data[0],
                            sort: data[1] as SortType,
                          }),
                        );
                      }
                    }}
                  />
                  {tabsConfig[tableTabs].children}
                </Card>
              </div>
            </div>
          </Tooltip>
        ) : (
          <div className="card-table">
            {mode === IndexFiltersMode.Filtering ? null : (
              <div className="btn-delete">
                <div>
                  <Tooltip content="Delete all">
                    <CustomButton icon={DeleteIcon} onClick={handleOpenModalDeleteAll} />
                  </Tooltip>
                </div>
                {!tableTabs ? (
                  <div className="btn-sync ml-8">
                    <Tooltip content="Sync rules">
                      <CustomButton icon={RefreshIcon} onClick={handleSync} loading={autoSyncStatus.isLoading} />
                    </Tooltip>
                  </div>
                ) : null}
              </div>
            )}
            <Card padding={'0'}>
              <IndexFilters
                queryValue={tableTabs ? inputSearchBlockFields : inputSearchBlockAccess}
                queryPlaceholder={
                  tableTabs
                    ? 'Searching by email, phone number, name, country, address'
                    : 'Searching by IP, country, state/province, internet provider'
                }
                onQueryChange={handleInputChangeTable}
                onQueryClear={() => {
                  handleInputChangeTable('');
                }}
                tabs={tabsConfig}
                selected={tableTabs}
                sortOptions={sortOptions}
                sortSelected={
                  tableTabs
                    ? [`${dataTableBlockFields.sortBy} ${dataTableBlockFields.sort.toLowerCase()}`]
                    : [`${dataTableBlockAccess.sortBy} ${dataTableBlockAccess.sort.toLowerCase()}`]
                }
                onSelect={(index) => {
                  dispatch(blockCheckoutSlice.actions.handleTableTabs(index));
                }}
                canCreateNewView={false}
                filters={tableTabs ? BlockCheckoutFilter().filters : BlackListFilter().filters}
                appliedFilters={tableTabs ? BlockCheckoutFilter().appliedFilters : BlackListFilter().appliedFilters}
                onClearAll={handleClearAllFilter}
                cancelAction={{ onAction: () => { } }}
                mode={mode}
                setMode={setMode}
                onSort={(value: string[]) => {
                  const data = value[0].split(' ');
                  if (tableTabs) {
                    dispatch(
                      blockCheckoutSlice.actions.handleDataTable({
                        ...dataTableBlockFields,
                        sortBy: data[0],
                        sort: data[1] as SortType,
                      }),
                    );
                  } else {
                    dispatch(
                      blockCheckoutAccessSlice.actions.handleBlackListTable({
                        ...dataTableBlockAccess,
                        sortBy: data[0],
                        sort: data[1] as SortType,
                      }),
                    );
                  }
                }}
              />
              {tabsConfig[tableTabs]?.children}
            </Card>
          </div>
        )}

        <Modal
          open={isOpenModalDelete}
          onClose={handleCloseModalDelete}
          title="Delete all"
          primaryAction={{
            destructive: true,
            content: 'Delete',
            onAction: handleDeleteAllBlockCheckout,
            loading: deleteAllBlockCheckoutStatus.isLoading || deleteAllBlockAccessStatus.isLoading,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: handleCloseModalDelete,
            },
          ]}
        >
          <Modal.Section>
            <RegularText>If you deleted all rules, you won't be able to revert it</RegularText>
          </Modal.Section>
        </Modal>
      </BlockCheckoutStyled>
    </Layout>
  );
};

export default memo(BlockCheckout);
