/* eslint-disable jsx-a11y/img-redundant-alt */
import ButtonSupport from '@/components/ButtonSupport';
import CustomDatePicker from '@/components/datePicker';
import HelpCenter from '@/components/HelpCenter';
import Layout from '@/components/layout';
import { Enum, PATH } from '@/constants';
import { dateToTimeStamp } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { bannerContactSupportFromVASelector, bannerWarningDemoDataVASelector } from '@/redux/slice/banner.slice';
import { blockedDetailSelector, blockHistorySelector } from '@/redux/slice/blockedVisitor.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import {
  allAccessSelector,
  perPageSelector,
  tabsTableSelector,
  titleBtnDatePickerSelector,
  visitorDetailSelector,
  visitorLogSelector
} from '@/redux/slice/visitorAnalytics.slice';
import { Badge, Banner, Button, Collapsible, Link } from '@shopify/polaris';
import { RefreshIcon } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Analytics from './components/Analytics';
import AnalyticsChart from './components/Chart';
import HeatMap from './components/heatMap';
import TableAnalytics from './components/table';
import TopFiveList from './components/TopFiveList';
import { AnalyticsStyled } from './styled';

const SecondaryAction = (): JSX.Element => {
  const dispatch = useDispatch();
  const { userPlanFree } = userPlans();
  const perPage = useSelector(perPageSelector);
  const allAccess = useSelector(allAccessSelector);
  const blockHistoryTable = useSelector(blockHistorySelector);
  const visitorLogTable = useSelector(visitorLogSelector);
  const blockedDetailTable = useSelector(blockedDetailSelector);
  const visitorsDetailTable = useSelector(visitorDetailSelector);
  const titleBtn = useSelector(titleBtnDatePickerSelector);
  const selected = useSelector(tabsTableSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const fetchVisitor = apiCaller.useFetchVisitorListQuery(
    {
      ...visitorLogTable,
      sort: visitorLogTable.sort.toUpperCase(),
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      perPage,
    },
    { skip: selected === 1 || isSkip },
  );
  const fetchBlock = apiCaller.useFetchVisitorBlockListQuery(
    {
      ...blockHistoryTable,
      sort: blockHistoryTable.sort.toUpperCase(),
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
      perPage,
    },
    { skip: selected === 0 || isSkip },
  );
  const fetchChartAnalytics = apiCaller.useFetchChartAnalyticsQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
    },
    { skip: selected === 1 || isSkip },
  );
  const fetchChartDashboard = apiCaller.useFetchChartBlockQuery(
    {
      endDate: dateToTimeStamp(allAccess.endDate),
      startDate: dateToTimeStamp(allAccess.startDate),
    },
    { skip: selected === 0 || isSkip },
  );
  const handleRefreshClick = async () => {
    if (selected === 0) {
      await Promise.all([fetchVisitor.refetch(), fetchChartAnalytics.refetch()]);
    } else if (selected === 1) {
      await Promise.all([fetchBlock.refetch(), fetchChartDashboard.refetch()]);
    }
  };
  const onSaveDatePicker = (startDate: Date, endDate: Date) => {
    // AllAccess
    dispatch(slice.visitorAnalyticsSlice.actions.handleAllAccessTable({ ...visitorLogTable, startDate, endDate }));
    // Blocked
    dispatch(slice.blockedVisitorSlice.actions.handleBlockedDetail({ ...blockedDetailTable, startDate, endDate }));
    // Allow
    dispatch(slice.visitorAnalyticsSlice.actions.handleVisitorsDetailTable({ ...visitorsDetailTable, startDate, endDate }));
  };
  const onSaveTitleBtnDatePicker = (title: string) => {
    dispatch(slice.visitorAnalyticsSlice.actions.handleTitleBtnDatePicker(title));
    dispatch(slice.visitorAnalyticsSlice.actions.handleTitleBtnDatePickerVisitorsDetail(title));
    dispatch(slice.blockedVisitorSlice.actions.handleTitleBtnDatePickerBlockedDetail(title));
  };

  return (
    <div className="d-flex">
      <Button
        onClick={handleRefreshClick}
        loading={fetchVisitor.isLoading && fetchChartAnalytics.isLoading && fetchBlock.isLoading && fetchChartDashboard.isLoading}
        icon={RefreshIcon}
      >
        Refresh
      </Button>
      <div className="ml-8">
        <CustomDatePicker
          titleButton={titleBtn}
          setTitleButton={onSaveTitleBtnDatePicker}
          startDate={allAccess.startDate}
          endDate={allAccess.endDate}
          onSave={onSaveDatePicker}
          isShowSelectedTime={true}
          disabled={userPlanFree}
          conditions
        />
      </div>
    </div>
  );
};

function AnalyticsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userPlanFree } = userPlans();
  const bannerContactSupportFromVA = useSelector(bannerContactSupportFromVASelector);
  const bannerWarningDemoDataVA = useSelector(bannerWarningDemoDataVASelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const [handleAnalytic, handleAnalyticStatus] = apiCaller.useHandleAnalyticStatusMutation();
  const handleChangeStatusAnalytics = () => {
    handleAnalytic();
  }

  return (
    <Layout
      layoutProps={{
        title: 'Visitor Analytics',
        fullWidth: true,
        primaryAction: dataSettings && dataSettings.settings.user.plan !== Enum.UserPlan.FREE ?
          (
            <div className="d-flex">
              <HelpCenter />
              <div className="ml-8">
                <ButtonSupport />
              </div>
            </div>
          ) : (
            <Button
              onClick={handleChangeStatusAnalytics}
              variant="primary"
              tone={dataSettings && dataSettings?.settings?.enableVisitorAnalytics ? "critical" : "success"}
              loading={handleAnalyticStatus.isLoading}
            >
              {dataSettings && dataSettings.settings.enableVisitorAnalytics ? 'Deactivate Visitor Analytics' : 'Activate Visitor Analytics'}
            </Button>
          ),
        titleMetadata: dataSettings && dataSettings.settings.user.plan === Enum.UserPlan.FREE ?
          dataSettings?.settings?.enableVisitorAnalytics ? <Badge tone="success">Tracking</Badge> : <Badge tone="enabled">Not track</Badge>
          :
          null
      }}
    >
      <AnalyticsStyled>
        <>
          {dataSettings && !dataSettings.settings.enableVisitorAnalytics ?
            <>
              {/* {userPlanFree && bannerProgressStatus && totalVisitor < dataSettings.settings.user.numberVisitorLimit ? (
                <div className="mb-16">
                  <Banner
                    tone="info"
                    onDismiss={() => {
                      dispatch(slice.banner.actions.handleBannerVisitorAnalyticProgress(false));
                    }}
                    action={{
                      content: 'Increase limit',
                      onAction: () => {
                        navigate(PATH.PRICING_PLAN);
                        mixpanel?.track('Analytics_increase_limit');
                      },
                    }}
                  >
                    <b>{totalVisitor}/{dataSettings.settings.user.numberVisitorLimit}</b> visitor access records. You nearly reach the limitation of Free plan. Increase the limit
                    to see full access list of the Analytics.
                  </Banner>
                </div>
              ) : null}

              {userPlanFree && bannerWarningStatus && totalVisitor >= dataSettings.settings.user.numberVisitorLimit ? (
                <div className="mb-16">
                  <Banner
                    tone="warning"
                    title="You have reached the limitation of Free plan. Visitor data has been paused due to the Free plan limitation."
                    action={{
                      content: 'Increase limit',
                      onAction: () => {
                        navigate(PATH.PRICING_PLAN);
                        mixpanel?.track('Analytics_increase_limit');
                      },
                    }}
                    onDismiss={() => {
                      dispatch(slice.banner.actions.handleBannerVisitorAnalyticWarning(false));
                    }}
                  >
                    <RegularText>
                      <b>{totalVisitor}/{dataSettings.settings.user.numberVisitorLimit}</b> visitor access records. Increase the limit to see full access list of the Analytics
                    </RegularText>
                  </Banner>
                </div>
              ) : null} */}
              <Collapsible id="banner-warning-demo-data"
                open={bannerWarningDemoDataVA}
                transition={{ duration: '200ms', timingFunction: 'ease-in-out' }}
              >
                <div className='mb-16 banner-warning-demo-data'>
                  <Banner
                    tone="warning"
                    onDismiss={() => {
                      dispatch(slice.banner.actions.handleBannerWarningDemoDataVA(false));
                    }}
                  >
                    Visitor analytics is currently deactivated. This is a demo of full visitor analytics. On the Free plan, we track the first 200 visitors with limited statistics. <Link onClick={() => navigate(PATH.PRICING_PLAN)}>Unlock full visitor analytics now.</Link>
                  </Banner>
                </div>
              </Collapsible>
            </>
            :
            <>
              {bannerContactSupportFromVA && userPlanFree ?
                <div className="mb-16">
                  <Banner
                    tone="info"
                    onDismiss={() => {
                      dispatch(slice.banner.actions.handleContactSupportFromVABanner(false));
                    }}
                    action={{
                      content: 'Learn more',
                      onAction: () => {
                        window.open('https://docs.ipblocker.io/getting-started/visitor-analytics', '_blank')
                      },
                    }}
                  >
                    Blocked visitors may still appear in Shopify's reports because Shopify logs all arrivals before third-party apps are activated.
                  </Banner>
                </div>
                : null
              }
            </>
          }
        </>
        <div className="mb-16">
          <SecondaryAction />
        </div>
        <Analytics />
        <div className="mt-16">
          <AnalyticsChart />
        </div>
        <div className="mt-16">
          <HeatMap />
        </div>
        <div className="mt-16">
          <TopFiveList />
        </div>
        <div className="mt-16">
          <TableAnalytics />
        </div>
      </AnalyticsStyled>
    </Layout>
  );
}

export default memo(AnalyticsPage);
