import BoldText from '@/components/BoldText';
import { Enum, PATH } from '@/constants';
import { ChartAnalyticType, CriteriaType, OtherCountries, RiskLevel } from '@/constants/enum';
import { dateToTimeStamp } from '@/helpers';
import UserPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import blockListSlice from '@/redux/slice/blockList.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { allAccessSelector } from '@/redux/slice/visitorAnalytics.slice';
import { IResponseApi } from '@/types/api/response.api';
import { Badge, BlockStack, Button, ButtonGroup, Card, InlineGrid } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TopFiveListStyled } from './styled';
import TopFiveItem from './TopFiveItem';

type TopFiveTypes = 'Browser' | 'OS' | 'ISP';

const TopFiveList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allAccess = useSelector(allAccessSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const dataSettings = useSelector(dataSettingsSelector);
  const [browserState, setBrowserState] = useState(RiskLevel.All);
  const [osState, setOsState] = useState(RiskLevel.All);
  const [ispState, setIspState] = useState(RiskLevel.All);

  const topHighRiskBrowsers = apiCaller.useGetTopHighRiskQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
    riskLevel: browserState,
    chartType: ChartAnalyticType.Browser
  }, { skip: isSkip });

  const topHighRiskOS = apiCaller.useGetTopHighRiskQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
    riskLevel: osState,
    chartType: ChartAnalyticType.OS
  }, { skip: isSkip });

  const topHighRiskProviders = apiCaller.useGetTopHighRiskQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
    riskLevel: ispState,
    chartType: ChartAnalyticType.Provider
  }, { skip: isSkip });
  const { userPlanFree } = UserPlans();
  const [activeButtonIndices, setActiveButtonIndices] = useState({
    Browser: 0,
    OS: 0,
    ISP: 0,
  });
  const handleButtonClick = useCallback(
    (index: number, type: TopFiveTypes) => {
      if (type === 'Browser') {
        setBrowserState(index === 1 ? RiskLevel.Risky : RiskLevel.All);
      } else if (type === 'OS') {
        setOsState(index === 1 ? RiskLevel.Risky : RiskLevel.All);
      } else if (type === 'ISP') {
        setIspState(index === 1 ? RiskLevel.Risky : RiskLevel.All);
      }
      setActiveButtonIndices((prevState) => ({
        ...prevState,
        [type]: index,
      }));
    },
    [],
  );

  const handleItemClick = (criteria: CriteriaType, data: IResponseApi.ITopFive, type: TopFiveTypes) => {
    dispatch(blockListSlice.actions.handleErrorRule([]));
    dispatch(
      blockListSlice.actions.handleSetting({
        isActive: true,
        priority: Enum.ListType.BlackList,
        type: Enum.ActionType.Block,
        criteria,
        country: [OtherCountries.All],
        referUrl: '',
        shortReferUrl: '',
        collectionId: [],
        state: [],
        city: [],
        ipAddress: '',
        ispName: type === 'ISP' ? [data.type] : [],
        ispCode: type === 'ISP' ? [data?.code || ''] : [],
        productId: [],
        pageId: [],
        deviceType: '',
        osName: type === 'OS' ? data.type.split(" ")[0] : '',
        browserName: type === 'Browser' ? [data.type] : [],
      }),
    );
    navigate(PATH.BLOCK_PAGE);
  };

  const handleRedirectPricingPlan = () => {
    navigate(PATH.PRICING_PLAN)
  }

  const sections: { title: string; data: IResponseApi.ITopFive[]; criteria: CriteriaType; type: TopFiveTypes }[] = [
    {
      title: "Top 5 Browsers",
      data: topHighRiskBrowsers.data?.data?.topBrowser || [],
      criteria: CriteriaType.UserAgent,
      type: "Browser",
    },
    {
      title: "Top 5 Operating Systems",
      data: topHighRiskOS.data?.data?.topOS || [],
      criteria: CriteriaType.UserAgent,
      type: "OS",
    },
    {
      title: "Top 5 Internet Service Providers",
      data: topHighRiskProviders.data?.data?.topProvider || [],
      criteria: CriteriaType.ISP,
      type: "ISP",
    }
  ];

  return (
    <TopFiveListStyled>
      <Card>
        <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 3, xl: 3 }} gap="400">
          {sections.map(({ title, data, type, criteria }) => (
            (userPlanFree && dataSettings?.settings.enableVisitorAnalytics) ? (
              <div key={title} className='hover-card'>
                <Card>
                  <div className='va-top-high-risk-title'>
                    <BoldText>{title} <span><Badge tone="info">Paid plan</Badge></span></BoldText>
                    <div className='group-btn' style={{ maxHeight: '30px' }}>
                      <ButtonGroup variant="segmented">
                        <Button
                          pressed={activeButtonIndices[type] === 0}
                        >
                          All
                        </Button>
                        <Button
                          pressed={activeButtonIndices[type] === 1}
                        >
                          High risk
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <BlockStack gap="200">
                    {data.map((item) => (
                      <TopFiveItem
                        key={item.type}
                        data={item}
                        onClick={() => handleItemClick(criteria, item, type)}
                      />
                    ))}
                  </BlockStack>
                </Card>
                <div className="overlay">
                  <Button onClick={handleRedirectPricingPlan}>Upgrade</Button>
                </div>
              </div>
            ) : (
              <div className='hover-card' key={title}>
                <Card>
                  <div className='va-top-high-risk-title'>
                    <BoldText>{title} {userPlanFree && !dataSettings?.settings.enableVisitorAnalytics ? <span><Badge tone="magic">Demo</Badge></span> : null}</BoldText>
                    <div>
                      <ButtonGroup variant="segmented">
                        <Button
                          pressed={activeButtonIndices[type] === 0}
                          onClick={() => handleButtonClick(0, type)}
                        >
                          All
                        </Button>
                        <Button
                          pressed={activeButtonIndices[type] === 1}
                          onClick={() => handleButtonClick(1, type)}
                        >
                          High risk
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <BlockStack gap="200">
                    {data.map((item) => (
                      <TopFiveItem
                        key={item.type}
                        data={item}
                        onClick={() => handleItemClick(criteria, item, type)}
                      />
                    ))}
                  </BlockStack>
                </Card>
              </div>
            )
          ))}
        </InlineGrid>
      </Card>
    </TopFiveListStyled>
  );
};

export default TopFiveList;
