/* eslint-disable no-useless-escape */
import { config } from '@/config';
import { Enum } from '@/constants';
import { UserPlan } from '@/constants/enum';
import { IItemBlock } from '@/redux/slice/blockList.slice';
import store from '@/redux/store';
import { IOptions, IToast } from '@/types/components';
import { HSBColor, hexToRgb, rgbToHsb } from '@shopify/polaris';
import dayjs from 'dayjs';

// const ipv4Pattern =
// /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/,
const ipv6Pattern =
  /(?:^|(?<=\s))(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(?=\s|$)/,
  // urlPattern = /^https:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//= ]*)$/,
  ipv4StartPattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){1,4}$/;
const ipv4Pattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}$/;

export const getSomeDaysAgo = (day: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - day);
  date.setHours(0, 0, 0);
  return date;
};

export const getLastMonth = () => {
  const firstDate = new Date();
  firstDate.setDate(1);
  firstDate.setMonth(firstDate.getMonth() - 1);
  const lastDate = new Date();
  lastDate.setDate(0);
  return {
    start: firstDate,
    end: lastDate,
  };
};
export const getLastSomesMonth = (month: number) => {
  const firtDate = new Date();
  firtDate.setDate(1);
  firtDate.setMonth(firtDate.getMonth() - month);
  const lastDate = new Date();
  lastDate.setMonth(lastDate.getMonth() - month + 1);
  lastDate.setDate(0);
  return {
    start: firtDate,
    end: lastDate,
  };
};

export const validateIp = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,
    );
};

export const validateListIp = (ips: string): boolean => {
  const listIp = ips.replace(/\s+/g, '').split(',');
  for (let ip of listIp) {
    if (!ipv4Pattern.test(ip) && !ipv6Pattern.test(ip)) return false;
  }
  return true;
};

export const validateIpAddress = (ip: string): boolean => {
  const listIp = ip.replace(/\s+/g, '').split(',');
  for (let ip of listIp) {
    if (!ipv4Pattern.test(ip) && !ipv6Pattern.test(ip)) return false;
  }
  return true;
};

export const validateIpStartWith = (ip: string): boolean => {
  return ipv4StartPattern.test(ip.replace(/\.+$/, '')) || ipv6Pattern.test(ip);
};

export const validateListIpStartWith = (ips: string): boolean => {
  if (!ips) return false;
  const listIp = ips.replace(/\s+/g, '').split(',');
  for (let ip of listIp) {
    // bỏ các dấu chấm ở cuối chuỗi
    if (!ipv4StartPattern.test(ip.replace(/\.+$/, '')) && !ipv6Pattern.test(ip)) return false;
  }
  return true;
};

export function validateUrl(url: string) {
  const regex = /[(www\.)?a-zA-Z0-9@:%._\+\-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
  const result = url.toLowerCase().match(regex);
  if (result === null) return false;
  else return true;
}

function isObject(object: any) {
  return object != null && typeof object === 'object';
}

export function deepObjectEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);

    if ((areObjects && !deepObjectEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

export const mergeArrays = (arr1: any, arr2: any) => {
  const merged = arr1.concat(arr2);
  const uniqueValues = merged.reduce((acc: any, item: any) => {
    const existingItem = acc.find((i: any) => i.value === item.value);
    if (!existingItem) {
      acc.push(item);
    }
    return acc;
  }, []);

  return uniqueValues;
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const hexToHSB = (color: string): HSBColor => {
  const rbgColor = hexToRgb(color);
  const hsbColor = rgbToHsb(rbgColor);
  return hsbColor;
};

export const checkShowErrorInline = (
  res: any,
): {
  status: boolean;
  msg: string;
} => {
  try {
    if ('data' in res) {
      return {
        status: res.data.state !== 1,
        msg: res.data.msg,
      };
    } else {
      return {
        status: true,
        msg: res.error.data.message.toString() || 'Something happened',
      };
    }
  } catch (err) {
    return {
      status: true,
      msg: 'Something happened',
    };
  }
};

export const handleToastMutation = (res: any): IToast => {
  try {
    if ('data' in res) {
      if (res.data.state !== 1) {
        return {
          isOpen: true,
          content: res.data.msg || 'Failed',
          error: true,
        };
      } else {
        return {
          isOpen: true,
          content: res.data.msg || 'Saved',
          error: false,
        };
      }
    } else {
      return {
        isOpen: true,
        content: res.data.msg || 'Something happened',
        error: false,
      };
    }
  } catch (err) {
    return {
      isOpen: true,
      content: 'Something happened',
      error: true,
    };
  }
};

export const convertToCamelCase = (str: string): string =>
  str
    .replace(/"/g, '')
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    .replace(/(\s|\b)(\w)/g, (m, space, chr) => chr.toLowerCase());

export const openCrisp = () => {
  try {
    $crisp.push(['do', 'chat:open']);
  } catch (error) {
    console.log(error);
  }
};

export const sendMessageCrisp = (text: string, event?: string[]) => {
  try {
    if (event) {
      $crisp.push(['set', 'session:event', event]);
    }
    $crisp.push(['do', 'chat:open']);
    $crisp.push(['do', 'message:send', ['text', text]]);
  } catch (err) {
    console.log(err);
  }
};

export const handleExportChart = (typeChart: number, startDate: Date, endDate: Date) => {
  window.open(
    `${process.env.REACT_APP_API_END_POINT
    }visitor/details/stats/export?typeChart=${typeChart}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&embedded=${config.embedded
    }&hmac=${config.hmac}&host=${config.host}&locale=${config.locale}&shop=${config.shop}&session=${config.session}&timestamp=${config.timestamp
    }`,
  );
};

export const timeConvertedByTimezone = (timeWithTimezone: Date, timezone: number) => {
  timeWithTimezone.setUTCHours(timezone);
  return timeWithTimezone;
};

// Check if your browser blocks localStorage
export const isLocalStorageSupported = () => {
  try {
    const testKey = '__test__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
};

export const truncateString = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
};

export const disablePlan = (plans: UserPlan[]) => {
  const plan = store.getState().dataSettings.data?.settings.user.plan;
  const index = plans.findIndex((item) => item === plan);
  return index !== -1;
};
export const getStoreNameFromStoreUrl = (storeUrl: string) => storeUrl.match(/^(.*?)\.myshopify\.com/)?.[1];

export const formatDate = (date: number | Date, format?: string) => {
  return dayjs(typeof date === 'number' ? date * 1000 : date).format(format ? format : 'D MMM YYYY, h:mm a');
};

export const dateToTimeStamp = (date: Date, isOrigin?: boolean) => {
  return isOrigin ? +date : dayjs(date).unix() * 1000;
};

export const secondToTime = (second: number) => [Math.floor(second / 3600).toString().padStart(2, '0'),
Math.floor((second % 3600) / 60).toString().padStart(2, '0'),
(second % 60).toString().padStart(2, '0')
].join(':');

export const downgradePlan = (currentPlan: UserPlan, newPlan: UserPlan) => {
  const planLevel = Object.values(UserPlan).map((p) => String(p));
  const currentPlanLevel = planLevel.indexOf(currentPlan);
  const nextPlanLevel = planLevel.indexOf(newPlan);
  return currentPlanLevel > nextPlanLevel;
};

export const formatCreatedAt = (createdAt: number) => {
  const currentTime = new Date().getTime();
  const timeDiff = currentTime - createdAt;
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (minutes <= 1) {
    return `1 minute ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours <= 1) {
    return `${hours} hour ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days <= 1) {
    return `${days} day ago`;
  } else if (days < 30) {
    return `${days} days ago`;
  } else if (months <= 1) {
    return `${months} month ago`;
  } else if (months < 12) {
    return `${months} months ago`;
  } else if (years <= 1) {
    return `${years} year ago`;
  } else {
    return `${years} years ago`;
  }
};

export function validateEmail(email: string) {
  const regex = new RegExp(/^[\w.-]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+$/);
  const result = email.match(regex);
  if (result === null) return false;
  else return true;
}

export function uniq(a: Array<any>) {
  return Array.from(new Set(a));
}

export const isRedirectInvalid = (setting: IItemBlock) => {
  return (
    setting.type === Enum.ActionType.Redirect &&
    (!setting.linkRedirect || (!!setting.linkRedirect && !validateUrl(setting.linkRedirect)))
  );
};

export function removeFalsyValues<T extends Record<string, any>>(obj: T): Partial<T> {
  const result: Partial<T> = {};

  (Object.keys(obj) as Array<keyof T>).forEach((key) => {
    const value = obj[key];
    if (Boolean(value) && (!Array.isArray(value) || value.length > 0)) {
      result[key] = value;
    }
  });

  return result;
}

export function convertArraysToStrings(obj: { [key: string]: any }): { [key: string]: any } {
  let newObj: { [key: string]: any } = { ...obj };
  for (let key in newObj) {
    if (Array.isArray(newObj[key])) {
      newObj[key] = newObj[key].join(', ');
    }
  }

  return newObj;
}
export const getOptionsSelectedRemove = (newValue: Array<string>, oldValue: Array<IOptions>) => {
  const optionSelected = oldValue.filter((option) => newValue.includes(option.value));
  return optionSelected;
};
export const getOptionsSelectedAdd = (newValue: Array<string>, oldValue: Array<IOptions>, options: Array<IOptions>) => {
  const selectedOptions = options.filter((option) => newValue.includes(option.value));
  return [...oldValue, ...selectedOptions].filter((item, index, self) => index === self.findIndex((t) => t.value === item.value));
};

export const daysSinceInstallation = (installationDateStr: string) => {
  const installationDate = dayjs(installationDateStr);
  const currentDate = dayjs();

  return currentDate.diff(installationDate, 'day');
};

export const findIPv4Range = (ip: string): string | null => {
  const octets = ip.split('.');
  if (octets.length !== 4) return null;

  const octet1 = octets[0];
  const octet2 = octets[1];
  const octet3 = octets[2];
  const octet4 = octets[3];

  if (octet4 === '0') {
    if (octet3 === '0') {
      if (octet2 === '0') {
        return `${octet1}.0.0.0/8`;
      } else {
        return `${octet1}.${octet2}.0.0/16`;
      }
    } else {
      return `${octet1}.${octet2}.${octet3}.0/24`;
    }
  } else {
    return `${octet1}.${octet2}.${octet3}.0/24`;
  }
};

export const findIPv6Range = (ip: string): string | null => {
  const groups = ip.split(':').map((group) => group.padStart(4, '0'));
  if (groups.length !== 8) return null;

  const group1 = groups[0];
  const group2 = groups[1];
  const group3 = groups[2];
  const group4 = groups[3];

  if (group4 === '0000') {
    if (group3 === '0000') {
      if (group2 === '0000') {
        return `${group1}::/16`;
      } else {
        return `${group1}:${group2}::/32`;
      }
    } else {
      return `${group1}:${group2}:${group3}::/48`;
    }
  } else {
    return `${group1}:${group2}:${group3}::/48`;
  }
};

export const findIPRange = (ip: string): string | null => {
  if (ip.includes('.')) {
    return findIPv4Range(ip);
  } else if (ip.includes(':')) {
    return findIPv6Range(ip);
  }
  return null;
};

export const shouldShowBannerReview = (): boolean => {
  const now = new Date();
  const options = { timeZone: 'Asia/Bangkok' };
  const localTime = new Date(now.toLocaleString('en-US', options));
  const dayOfWeek = localTime.getDay();
  const hour = localTime.getHours();

  if (dayOfWeek === 6 || dayOfWeek === 0 || (dayOfWeek === 1 && hour < 8)) {
    return false;
  }
  return true;
}
