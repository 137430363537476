import BoldText from '@/components/BoldText';
import ModalConfirm from '@/components/ModalConfirm';
import RegularText from '@/components/RegularText';
import AutoCompleteWithTag from '@/components/autoCompleteWithTag';
import Layout from '@/components/layout';
import { config } from '@/config';
import { PATH } from '@/constants';
import { BREAKPOINT, BlockCheckoutType, NameType } from '@/constants/enum';
import { checkShowErrorInline, handleToastMutation, uniq, validateEmail } from '@/helpers';
import useScope from '@/hooks/Scope';
import useCountry from '@/hooks/useCountry';
import { apiCaller } from '@/redux/query';
import blockCheckoutSlice, {
  blockRuleSelectedBackupSelector,
  blockRuleSelectedSelector,
  conditionSelector,
} from '@/redux/slice/blockCheckout.slice';
import toastSlice from '@/redux/slice/toast.slice';
import { Badge, Button, Card, InlineGrid, Link, List, RadioButton, Select, Text, TextField } from '@shopify/polaris';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { conditionConfig, conditionOptions } from './config';
import { BlockCheckoutPageStyled } from './styled';
import ButtonSupport from '@/components/ButtonSupport';
import { nameType } from '@/constants/options';
import HelpCenter from '@/components/HelpCenter';
import helpCenterSlice, { filterSubKeyHelpCenterWhenEditBlockCheckout } from '@/redux/slice/helpCenter.slice';

const BlockCheckoutPage = () => {
  const condition = useSelector(conditionSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const ruleSelected = useSelector(blockRuleSelectedSelector);
  const ruleBackupSelected = useSelector(blockRuleSelectedBackupSelector);
  const optionsCountry = useCountry();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const [deleteRule, deleteRuleStatus] = apiCaller.useRemoveBlockCheckoutRulesMutation();
  const [mutationBlockRules, mutationBlockRulesStatus] = apiCaller.useMutationBlockCheckoutRulesMutation();
  const conditionSelected = conditionConfig.find((item) => item.value.includes(ruleSelected.criteria));
  const scope = useScope();
  const [error, setError] = useState<string | React.ReactElement>('');
  const handleChangeNameType = (value: NameType) => {
    dispatch(
      blockCheckoutSlice.actions.handleBlockRuleSelected({
        ...ruleSelected,
        value: '',
        fieldName: value,
      }),
    );
    setError('');
  };
  const handleError = (value: string) => {
    setError(value);
  };

  const onRemoveTag = (tag: string) => {
    const list = [...ruleSelected.value.split(',')];
    const newList = list.filter((code) => {
      return code.trim() !== tag.trim();
    });
    handleChange('value')(newList.join(','));
  };
  const fieldValueConfig = [
    {
      key: BlockCheckoutType.Email,
      fieldName: 'email',
      onBlur: () => {
        if (ruleSelected.id && ruleSelected.value.includes(',')) {
          handleError("You can't edit multiple email");
        } else if (ruleSelected.value) {
          let condition = true;
          ruleSelected.value
            .trim()
            .split(',')
            .filter(Boolean)
            .forEach((item: string) => {
              if (!validateEmail(item.trim())) condition = false;
            });
          if (!condition) {
            handleError('Email invalid');
          }
        }
      },
      helpText: 'You can enter multiple emails at the same time, separated by commas. Example: test@gmail.com,test1@gmail.com',
    },
    {
      key: BlockCheckoutType.EmailDomain,
      fieldName: 'email',
      onBlur: () => {
        if (ruleSelected.id && ruleSelected.value.includes(',')) {
          handleError("You can't edit multiple email");
        } else if (ruleSelected.value) {
          let condition = true;
          ruleSelected.value
            .trim()
            .split(',')
            .forEach((item: string) => {
              if (item.includes(' ')) condition = false;
            });
          if (!condition) {
            handleError('Email contain invalid');
          }
        }
      },
      helpText:
        'You can enter multiple words contained in email addresses simultaneously, separated by commas. For instance: abc,test',
    },
    {
      key: BlockCheckoutType.PhoneNumber,
      fieldName: 'phone number',
      onBlur: () => {
        if (ruleSelected.id && ruleSelected.value.includes(',')) {
          handleError("You can't edit multiple phone number");
        }
      },
      helpText: 'You can enter multiple number at the same time, separated by commas. Example: 012345678,0987654321',
    },
    {
      key: BlockCheckoutType.ZipCode,
      fieldName: 'zip code',
      onBlur: () => {
        if (ruleSelected.id && ruleSelected.value.includes(',')) {
          handleError("You can't edit multiple zip code");
        }
      },
      helpText: 'You can enter multiple zip code at the same time, separated by commas. Example: 10020,00120',
    },
    {
      key: BlockCheckoutType.Address,
      fieldName: 'address',
    },
    {
      key: BlockCheckoutType.Country,
      fieldName: 'country',
    },
    {
      key: BlockCheckoutType.Name,
      fieldName: 'name',
      helpText:
        ruleSelected.fieldName === NameType.FullName
          ? 'You can enter multiple names at the same time, separated by commas. Name must be in {Firstname Lastname} format. Example: John Smith,Michael Brown'
          : 'You can enter multiple names at the same time, separated by commas. Example: John,Michael',
      onBlur: () => {
        if (ruleSelected.id && ruleSelected.value.includes(',')) {
          handleError("You can't edit multiple name");
        } else if (ruleSelected.value && ruleSelected.fieldName === NameType.FullName) {
          let condition = true;
          ruleSelected.value
            .trim()
            .split(',')
            .forEach((item: string) => {
              const fullname = item.split(' ');
              const firstName = fullname[0];
              const lastName = fullname[1];
              if (!firstName || !lastName) {
                condition = false;
              }
            });
          if (!condition) {
            handleError('Name wrong format');
          }
        }
      },
    },
  ].find((item) => {
    return item.key === ruleSelected.criteria;
  });

  const ruleSummary: Array<{
    title: string;
    value?: string;
    regular?: boolean;
    visible?: BlockCheckoutType[];
  }> = useMemo(() => {
    // [title , value , regular,condition visible]
    return [
      {
        title: 'Status',
        value: ruleSelected.isActive ? 'Enabled' : 'Disabled',
        regular: true,
      },
      {
        title: 'Condition',
        value: conditionSelected?.label || '',
      },
      {
        title: 'Email address',
        value: ruleSelected.value,
        visible: [BlockCheckoutType.Email, BlockCheckoutType.EmailDomain],
      },
      {
        title: 'Phone number',
        value: ruleSelected.value,
        visible: [BlockCheckoutType.PhoneNumber],
      },
      {
        title: 'Custom name',
        value: ruleSelected.value,
        visible: [BlockCheckoutType.Name],
      },

      {
        title: 'Country',
        value: ruleSelected.value
          .split(',')
          .map((item) => optionsCountry.renderCountry(item))
          .join(', '),
        visible: [BlockCheckoutType.Country],
      },

      {
        title: 'Address',
        value: ruleSelected.value,
        regular: false,
        visible: [BlockCheckoutType.Address],
      },

      {
        title: 'Zip code',
        value: ruleSelected.value,
        regular: false,
        visible: [BlockCheckoutType.ZipCode],
      },
    ];
  }, [conditionSelected?.label, optionsCountry, ruleSelected.isActive, ruleSelected.value]);

  const handleChange = (key: keyof typeof ruleSelected) => (value: string | string[] | boolean) => {
    if (error) {
      setError('');
    }
    dispatch(
      blockCheckoutSlice.actions.handleBlockRuleSelected({
        ...ruleSelected,
        [key]: value,
      }),
    );
  };

  const handleDelete = () => {
    if (ruleSelected.id) {
      deleteRule(ruleSelected.id).then((res) => {
        const condition = checkShowErrorInline(res);
        if (!condition.status) {
          setIsOpenModalDelete(false);
          navigate(PATH.BLOCKED_CHECKOUT);
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      });
    }
  };
  const disabled =
    !!error ||
    !ruleSelected.value ||
    (ruleSelected.criteria === BlockCheckoutType.Country
      ? ruleSelected.value.length === 0
      : typeof ruleSelected.value === 'string' &&
        ruleSelected.value.split(',').every((element: string) => element.trim() === ''));

  const handleSave = () => {
    mutationBlockRules({
      id: ruleSelected.id,
      criteria: ruleSelected.criteria,
      isActive: ruleSelected.id ? ruleSelected.isActive : true,
      shop: config.shop || '',
      value: ruleSelected.value,
      fieldName: ruleSelected.criteria === BlockCheckoutType.Name ? ruleSelected.fieldName : undefined,
    }).then((res) => {
      if ('data' in res) {
        const errorStatus = checkShowErrorInline(res);
        if (errorStatus.status) {
          let error: string | React.ReactElement | undefined;
          if (res.data?.url) {
            error = (
              <Text as="span" variant="bodyMd" tone="critical">
                Unable to add some new entries due to invalid format or existing values: See details{' '}
                <Link url={res.data?.url}>here</Link>
              </Text>
            );
          } else {
            error = (
              <div>
                {res.data?.invalidRules.map((item, index) => (
                  <p key={item.errValue}>
                    <b>{item.errValue} </b>
                    is already included in an existing rule. Try to add another {fieldValueConfig?.fieldName}.
                  </p>
                ))}
              </div>
            );
          }
          setError(error);
        } else {
          navigate(PATH.BLOCKED_CHECKOUT);
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
        }
      }
    });
  };

  useEffect(() => {
    if (ruleSelected.criteria === BlockCheckoutType.None) {
      navigate(PATH.BLOCKED_CHECKOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleSelected.criteria === BlockCheckoutType.None]);
  return (
    <BlockCheckoutPageStyled>
      <Layout
        layoutProps={{
          title: `${ruleSelected.id ? 'Edit ' : ''}Block checkout`,
          backAction: {
            onAction: () => navigate(PATH.BLOCKED_CHECKOUT),
          },
          primaryAction: (
            <div className="d-flex">
              <HelpCenter />
              <div className="ml-8">
                <ButtonSupport />
              </div>
            </div>
          ),
        }}
      >
        <InlineGrid columns={isMobile ? 1 : ['twoThirds', 'oneThird']} gap={'400'}>
          <div>
            <Card padding={'400'}>
              <div className="block-checkout-page-status">
                <BoldText>
                  Rule status{' '}
                  <Badge tone={ruleSelected.isActive ? 'success' : undefined}>
                    {!ruleSelected.isActive ? 'Disabled' : 'Enable'}
                  </Badge>
                </BoldText>
                <Button
                  onClick={() => handleChange('isActive')(!ruleSelected.isActive)}
                  variant={ruleSelected.isActive ? undefined : 'primary'}
                >
                  {ruleSelected.isActive ? 'Disabled' : 'Enable'}
                </Button>
              </div>
              <RegularText>Active rules are on and running. Inactive rules are off and not triggering actions.</RegularText>
            </Card>
            <div className="mt-16 block-page-content">
              <Card padding={'400'}>
                <Select
                  disabled={!!ruleSelected.id}
                  label="Select condition"
                  value={ruleSelected.criteria}
                  options={conditionOptions}
                  onChange={(value) => {
                    setError('');
                    const subKey = filterSubKeyHelpCenterWhenEditBlockCheckout.find((filter) =>
                      filter.criteria.includes(value as BlockCheckoutType),
                    )?.value;
                    if (subKey) {
                      dispatch(helpCenterSlice.actions.handleSubKey(subKey));
                    }
                    dispatch(blockCheckoutSlice.actions.handleCondition(value as BlockCheckoutType));
                    dispatch(
                      blockCheckoutSlice.actions.handleBlockRuleSelected({
                        ...ruleSelected,
                        criteria: value as BlockCheckoutType,
                        value: '',
                      }),
                    );
                  }}
                />
                <div className="mt-8" />

                {condition === BlockCheckoutType.Email ? (
                  <div className="email-condition">
                    <RadioButton
                      disabled={!!ruleSelected.id}
                      label="Specific email"
                      checked={ruleSelected.criteria === BlockCheckoutType.Email}
                      id="specificEmail"
                      name="specificEmail"
                      onChange={() => handleChange('criteria')(BlockCheckoutType.Email)}
                    />
                    <RadioButton
                      label="Email contain"
                      disabled={!!ruleSelected.id}
                      checked={ruleSelected.criteria === BlockCheckoutType.EmailDomain}
                      id="emailContain"
                      name="emailContain"
                      onChange={() => handleChange('criteria')(BlockCheckoutType.EmailDomain)}
                    />
                  </div>
                ) : null}
                {ruleSelected.criteria === BlockCheckoutType.Country ? (
                  <AutoCompleteWithTag
                    allowMultiple={!ruleSelected.id}
                    label={conditionSelected?.labelFieldValue || ''}
                    selectedOptions={ruleSelected?.value.split(',').filter((item) => !!item)}
                    setSelectedOptions={(select) => {
                      if (select.length === 0) {
                        setError('Country is required');
                      } else {
                        setError('');
                      }
                      handleChange('value')(select.join(','));
                    }}
                    options={optionsCountry.listCountry}
                    error={error}
                    onBlur={() => {
                      if (!ruleSelected.value) {
                        setError('Country is required');
                      }
                    }}
                  />
                ) : ruleSelected.criteria === BlockCheckoutType.Name ? (
                  <>
                    <Select label="Type" options={nameType} value={ruleSelected.fieldName} onChange={handleChangeNameType} />
                    <TextField
                      autoFocus
                      autoComplete="off"
                      label={conditionSelected?.labelFieldValue || ''}
                      value={ruleSelected.value}
                      onChange={handleChange('value')}
                      onBlur={fieldValueConfig?.onBlur}
                      helpText={fieldValueConfig?.helpText}
                      error={error}
                    />
                  </>
                ) : (
                  <TextField
                    autoFocus
                    autoComplete="off"
                    label={conditionSelected?.labelFieldValue || ''}
                    value={ruleSelected.value}
                    onChange={handleChange('value')}
                    onBlur={fieldValueConfig?.onBlur}
                    helpText={fieldValueConfig?.helpText}
                    error={error}
                  />
                )}
                {ruleSelected.criteria !== BlockCheckoutType.Country && ruleSelected.criteria !== BlockCheckoutType.Address ? (
                  <div className="mt-8">
                    {uniq(ruleSelected.value.split(',')?.map((item: string) => item.trim()))
                      .filter((item) => item !== '')
                      .splice(0, 500)
                      .map((itemAfterTrim) => {
                        return (
                          <div className="mt-8 mr-8 Polaris-Tag" key={itemAfterTrim}>
                            <h1 title={itemAfterTrim} className="Polaris-Tag__TagText">
                              {itemAfterTrim}
                            </h1>
                            <button className="Polaris-Tag__Button" onClick={() => onRemoveTag(itemAfterTrim)}>
                              <div>X</div>
                            </button>
                          </div>
                        );
                      })}
                  </div>
                ) : null}
              </Card>
            </div>
          </div>

          <div className="rule-summary-container">
            <Card padding={'0'}>
              <div className="block-page-rule-summary">
                <BoldText>Rule summary</BoldText>
              </div>
              <div className="block-page-rule-summary-content">
                <List type="bullet">
                  {ruleSummary
                    .filter((item) => {
                      if (!item.visible) return true;
                      return item.visible.includes(ruleSelected.criteria as BlockCheckoutType);
                    })
                    .map((item) => {
                      return (
                        <>
                          <List.Item>
                            {item.title}:{' '}
                            {!item.value ? (
                              <Text as="span" variant="bodyMd" tone="subdued">
                                None
                              </Text>
                            ) : item.regular ? (
                              item.value
                            ) : (
                              <b>{item.value}</b>
                            )}
                          </List.Item>
                        </>
                      );
                    })}
                </List>
              </div>
            </Card>
          </div>
        </InlineGrid>
        <div className="block-page-group-button">
          {ruleSelected.id ? (
            <Button
              onClick={() => {
                setIsOpenModalDelete(true);
              }}
              tone="critical"
              variant="primary"
            >
              Delete
            </Button>
          ) : null}
          <Button
            disabled={
              disabled ||
              scope.isViewOnly ||
              (!!ruleSelected.id && JSON.stringify(ruleSelected) === JSON.stringify(ruleBackupSelected))
            }
            loading={mutationBlockRulesStatus.isLoading}
            onClick={handleSave}
            variant="primary"
          >
            Save
          </Button>
        </div>

        <ModalConfirm
          onClose={() => setIsOpenModalDelete(false)}
          isLoading={deleteRuleStatus.isLoading}
          isOpen={isOpenModalDelete}
          title="Delete rule?"
          onConfirm={handleDelete}
          sectionText="If you delete the rule, you won't be able to revert it."
        />
      </Layout>
    </BlockCheckoutPageStyled>
  );
};

export default memo(BlockCheckoutPage);
