export enum ListType {
  BlackList = '1',
  WhiteList = '2',
}

export enum PriorityType {
  Blocked = '1',
  Allowed = '2',
}

export enum ActionType {
  Block = '1',
  Redirect = '2',
  WhiteList = '0',
}

export enum CriteriaType {
  IpAddress = '1',
  IpAddressStartWith = '4',
  Country = '3',
  Province = '2',
  ISP = '5',
  Collection = '6',
  Product = '7',
  ReferralLink = '8',
  UserAgent = '9',
  IpRanges = '10',
  SpecificPage = '11',
}

enum BackgroundType {
  Color = '1',
  Image = '2',
  GRADIENT = '3',
}

enum RiskType {
  Safe = 'safe',
  Risky = 'risky',
  Dangerous = 'dangerous',
}

enum RiskLevelScore {
  Safe = 34,
  Risky = 66,
  Dangerous = 101,
}

export enum RiskLevel {
  All = 'all',
  Safe = 'safe',
  Risky = 'risky',
  Dangerous = 'dangerous',
}

export enum ChartAnalyticType {
  Browser = 'Browser',
  OS = 'OS',
  Provider = 'Provider',
}

enum StatusVisitorsLogType {
  Allow = 'allow',
  Block = 'block',
}

enum TypeVisitorsLogType {
  Other = 'Other',
  Residential = 'Residential',
  Wireless = 'Wireless',
  Business = 'Business',
  VPN = 'VPN',
  TOR = 'TOR',
}

export enum UserPlan {
  FREE = 'free',
  PREMIUM = 'premium',
  ENTERPRISE = 'enterprise',
  SHOPIFYPLUS = 'shopify plus',
}

export enum SortType {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum ReviewType {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export enum DeviceType {
  SMART_PHONE = 1,
  TABLET = 2,
  PHABLET = 3,
  DESKTOP = 4,
}

export enum SelectedTab {
  Blacklist = 0,
  Whitelist = 1,
}

export enum SelectedTabModal {
  Blacklist = 0,
  Redirect = 1,
  Whitelist = 2,
}

export enum LimitNumberOfIP {
  LIMIT = 100,
}

export enum Subscription {
  Monthly = 'monthly',
  Yearly = 'annually',
}

export enum BREAKPOINT {
  XS = 490,
  SM = 768,
  MD = 1040,
  LG = 1800,
  XL = 1920,
}

export enum TypeChart {
  TOTAL_VISITOR = 1,
  TOTAL_BLOCKED = 2,
  SAFE = 3,
  RISK = 4,
  VPN = 5,
}

export enum SortByType {
  TOTAL_ACCESS = 'totalAccess',
  ACCESS_AT = 'accessAt',
  LAST_SEEN_ON = 'lastSeenOn',
}

export enum StatusIpLog {
  ALLOW = 'allow',
  BLOCK = 'block',
}

export enum UserPermission {
  View = 'view',
  ViewSensitive = 'view_sensitive',
  Edit = 'edit',
  FullAccess = 'full_access',
}

export enum UserRole {
  Admin = 'admin',
  Guest = 'guest',
  Support = 'supporter',
}

export enum App {
  Synctrack = 'synctrack',
  Blockify = 'blockify',
  Floatify = 'floatify',
  OT = 'ordertracking',
  Return = 'returnsdrive',
}

export enum TypeList {
  Allowed = 'allowed',
  Blocked = 'blocked',
}

export enum IsVpn {
  Yes = 'Yes',
  No = 'No',
  NotDetacted = 'Not Detected',
}

export enum OrderRisk {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum BlockCheckoutType {
  Email = 'email',
  EmailDomain = 'emailDomain',
  PhoneNumber = 'phoneNumber',
  Name = 'name',
  Country = 'country',
  Address = 'address',
  ZipCode = 'zipCode',
  None = 'none',
}

export enum CriteriaHidePayment {
  DayOfWeek = '1',
  NewCustomer = '4',
  RepeatCustomer = '5',
  CustomerTotalSpent = '6',
  CustomerTags = '7',
  Country = '10',
  EmailDomain = '11',
  Phone = '12',
  ZipCode = '13',
  Email = '14',
  State = '15',
  City = '16',
}

export enum DayType {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export enum ScopeGroups {
  processOrders = 'update_scope',
  validation = 'update_validation_scope',
  paymentCustomization = 'update_payment_scope',
  readContent = 'update_read_content_scope',
  writeOrders = 'write_orders',
  readOrders = 'read_orders',
}

export enum CommonCondition {
  equal = 'equal',
  notEqual = 'not_equal',
  match = 'match',
  notMatch = 'not_match',
  contain = 'contain',
  notContain = 'not_contain',
  greaterThan = 'greater_than',
  lessThan = 'less_than',
  greaterThanOrEqual = 'greater_than_or_equal',
  lessThanOrEqual = 'less_than_or_equal',
  startWith = 'start_with',
  endWith = 'end_with',
  in = 'in',
  notIn = 'not_in',
  between = 'between',
  notBetween = 'not_between',
}

export enum StatusExpressCheckout {
  enable = 'enable',
  disable = 'disable',
}

export enum CheckoutRules {
  payment = 'payment',
  shipping = 'shipping',
}

export enum ConditionHidePayment {
  DayOfTheWeek = '1',
  Customer = '2',
  Contact = '3',
  Address = '4',
}

export enum NameType {
  FirstName = 'first_name',
  LastName = 'last_name',
  FullName = 'full_name',
}

export enum OtherCountries {
  All = 'all',
  AllEu = 'allEu',
  NonEu = 'nonEu',
  NonUs = 'nonUs',
}

export enum Ga4Event {
  CheckoutRulesImpression = 'impress_checkout',
  SynctrackBannerImpression = 'impress_st',
  ReturnImpression = 'impress_returns',
  CheckoutRulesClick = 'click_checkout',
  ParetoClick = 'click_limit',
  SynctrackBannerClick = 'click_st',
  ReturnClick = 'click_returns',
  GeneralSetting = 'activate_impression',
  PricingPlan = 'pricing_plan_impression',
  SelectPlan = 'select_plan',
  ImpressOT = 'impress_OT',
  ImpressPareto = 'impress_limit',
  ClickOT = 'click_OT',

  ImpressEstimated = 'impress_est',
  ImpressCookies = 'impress_cookies',
  ImpressTrustify = 'impress_trustify',
  ImpressFacebookPixel = 'impress_fbpixel',
  ImpressAdoric = 'impress_adoric',
  ImpressHyperSKU = 'impress_hypersku',
  ClickEstimated = 'click_est',
  ClickCookies = 'click_cookies',
  ClickTrustify = 'click_trustify',
  ClickFacebookPixel = 'click_fbpixel',
  ClickHyperSKU = 'click_hypersku',
  ClickAdoric = 'click_adoric',
}

export enum SubKeyHelpCenter {
  IpAddress = 'IpAddress',
  Location = 'Location',
  Product = 'Product',
  Agent = 'Agent',
  Page = 'Page',
  Isp = 'Isp',
  Url = 'Url',
  Redirect = 'Redirect',
  WhiteList = 'Whitelist',
  WhiteListLocation = 'Whitelist_location',
  BlockCheckoutGeneral = 'Block_checkout_general',
  BlockCheckoutEmail = 'Block_checkout_email',
  BlockCheckoutPhoneNumber = 'Block_checkout_phonenumber',
  BlockCheckoutName = 'Block_checkout_name',
  BlockCheckoutCountry = 'Block_checkout_country',
  BlockCheckoutAddress = 'Block_checkout_address',
  BlockCheckoutZipcode = 'Block_checkout_zipcode',
}

export enum OnboardRuleType {
  BlockIP = 'block_ip',
  BlockCountry = 'block_country',
  BlockProvince = 'block_province',
  RedirectCountry = 'redirect_country',
  BlockVpnProxy = 'block_vpn_proxy',
  ProtectContent = 'protect_content',
  Skip = 'skip'
}

export default {
  ListType,
  ActionType,
  CriteriaType,
  BackgroundType,
  RiskType,
  RiskLevel,
  StatusVisitorsLogType,
  TypeVisitorsLogType,
  UserPlan,
  SortType,
  ReviewType,
  DeviceType,
  LimitNumberOfIP,
  SelectedTab,
  SelectedTabModal,
  TypeChart,
  SortByType,
  StatusIpLog,
  TypeList,
  BlockCheckoutType,
  DayType,
  CriteriaHidePayment,
  ScopeGroups,
  CommonCondition,
  StatusExpressCheckout,
  CheckoutRules,
  ConditionHidePayment,
  NameType,
  OtherCountries,
  PriorityType,
  RiskLevelScore,
  OnboardRuleType,
};
