/* eslint-disable jsx-a11y/img-redundant-alt */
import { images } from '@/asset';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import { PATH } from '@/constants';
import { ActionType, CriteriaType, ListType, OnboardRuleType } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import blockListSlice from '@/redux/slice/blockList.slice';
import dataSettingsSlice, { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import settingsSlice from '@/redux/slice/settings.slice';
import { Card, InlineGrid, Text } from '@shopify/polaris';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const initSettings = {
  isActive: true,
  id: undefined,
  linkRedirect: '',
  shortUrl: '',
  referUrl: '',
  shortReferUrl: '',
  note: '',
  collectionId: [],
  state: [],
  city: [],
  country: [],
  ipAddress: '',
  ispName: [],
  ispCode: [],
  productId: [],
  pageId: [],
  deviceType: '',
  osName: '',
  browserName: [],
};

const StepThree = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateWelcomeStep] = apiCaller.useUpdateWelcomeStepMutation();
  const dataSettings = useSelector(dataSettingsSelector);

  const listAction = useMemo(
    () => [
      {
        src: images.welcome_ip_address,
        title: 'Block IP address',
        description: 'Block specific IP address, IP range, etc',
        ruleType: OnboardRuleType.BlockIP,
        path: PATH.BLOCK_PAGE,
        action: () =>
          dispatch(
            blockListSlice.actions.handleSetting({
              ...initSettings,
              criteria: CriteriaType.IpAddress,
              type: ActionType.Block,
              priority: ListType.BlackList,
            }),
          ),
      },
      {
        src: images.welcome_block_country,
        title: 'Block unwanted country',
        description: 'Block specific country, group of countries, etc',
        path: PATH.BLOCK_PAGE,
        ruleType: OnboardRuleType.BlockCountry,
        action: () =>
          dispatch(
            blockListSlice.actions.handleSetting({
              ...initSettings,
              criteria: CriteriaType.Country,
              type: ActionType.Block,
              priority: ListType.BlackList,
            }),
          ),
      },
      {
        src: images.welcome_province,
        title: 'Block unwanted province',
        description: 'Block specific province, multiple provinces, etc',
        ruleType: OnboardRuleType.BlockProvince,
        path: PATH.BLOCK_PAGE,
        action: () =>
          dispatch(
            blockListSlice.actions.handleSetting({
              ...initSettings,
              criteria: CriteriaType.Province,
              type: ActionType.Block,
              priority: ListType.BlackList,
            }),
          ),
      },
      {
        src: images.welcome_redirect_country,
        title: 'Redirect based on country',
        description: 'Redirect to an URL with specific country',
        path: PATH.BLOCK_PAGE,
        ruleType: OnboardRuleType.RedirectCountry,
        action: () =>
          dispatch(
            blockListSlice.actions.handleSetting({
              ...initSettings,
              criteria: CriteriaType.Country,
              type: ActionType.Redirect,
              priority: ListType.BlackList,
            }),
          ),
      },
      {
        src: images.welcome_vpn,
        title: 'Block VPN & Proxy',
        description: 'Block all VPN and proxy access to your store',
        path: PATH.SETTINGS,
        ruleType: OnboardRuleType.BlockVpnProxy,
        action: () => dispatch(settingsSlice.actions.handleSelected(['blocker'])),
      },
      {
        src: images.welcome_protected_content,
        title: "Protect your store's content",
        description: 'Protect your store’s content by enabling this setting',
        ruleType: OnboardRuleType.ProtectContent,
        path: PATH.SETTINGS,
        action: () => dispatch(settingsSlice.actions.handleSelected(['content'])),
      },
    ],
    [dispatch],
  );

  return (
    <div className="step-three">
      <div className="welcome-title">
        <Text as="h3" variant="headingXl">
          Select a rule that best matches your needs
        </Text>
      </div>
      <div className="list-action mt-16">
        <InlineGrid columns={{ lg: 2, xl: 2, md: 2, sm: 1, xs: 1 }} gap={'400'}>
          {listAction.map((item) => {
            return (
              <Card padding={'0'} key={item.title}>
                <div
                  className="item"
                  onClick={async () => {
                    // await trackAction({
                    //   eventName: item.title,
                    //   position: 'welcome',
                    // });
                    await updateWelcomeStep({
                      step: 3,
                      ruleType: item.ruleType,
                    }).then((res) => {
                      if ('data' in res && dataSettings) {
                        item.action();
                        navigate(item.path, {
                          state: {
                            prePath: PATH.WELCOME,
                          },
                        });
                        dispatch(
                          dataSettingsSlice.actions.handleSettings({
                            ...dataSettings,
                            settings: {
                              ...dataSettings.settings,
                              welcomeStep: 3,
                              displayWelcome: false,
                            },
                          }),
                        );
                      }
                    });
                  }}
                >
                  <img src={item.src} alt={item.title} />
                  <div className="right-content">
                    <BoldText>{item.title}</BoldText>
                    <RegularText>{item.description}</RegularText>
                  </div>
                </div>
              </Card>
            );
          })}
        </InlineGrid>
      </div>
    </div>
  );
};

export default memo(StepThree);
