import blockedVisitorSlice, {
  ListBlock,
  blockHistorySelector,
  inputSearchSelector as inputSearchBlockedSelector,
} from '@/redux/slice/blockedVisitor.slice';
import visitorLogSlice, {
  ListVisitors,
  inputSearchSelector,
  visitorLogSelector,
} from '@/redux/slice/visitorAnalytics.slice';
import _debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filters from './Filters';

const useConfigTable = () => {
  const dispatch = useDispatch();
  const visitorLogTable = useSelector(visitorLogSelector);
  const inputSearchVisitorLog = useSelector(inputSearchSelector);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchVisitorLog = useCallback(
    _debounce((value: ListVisitors) => {
      dispatch(visitorLogSlice.actions.handleVisitorLogTable(value));
    }, 1000),
    [],
  );
  const onSearchChange = (value: string) => {
    dispatch(visitorLogSlice.actions.handleInputSearch(value));
    debounceSetSearchVisitorLog({
      ...visitorLogTable,
      search: value,
      page: 1,
    });
  };
  const visitorLog = {
    sortOptions: [
      { label: 'Total access', value: 'totalAccess asc', directionLabel: 'Ascending' },
      { label: 'Total access', value: 'totalAccess desc', directionLabel: 'Descending' },
      { label: 'Last seen on', value: 'lastSeenOn asc', directionLabel: 'Ascending' },
      { label: 'Last seen on', value: 'lastSeenOn desc', directionLabel: 'Descending' },
    ],
    sortSelected: [`${visitorLogTable.sortBy} ${visitorLogTable.sort}`],
    queryValue: inputSearchVisitorLog,
    onClearAll: () => {
      dispatch(visitorLogSlice.actions.handlePerPage('10'));
      dispatch(
        visitorLogSlice.actions.handleVisitorLogTable({
          ...visitorLogTable,
          page: 1,
          status: undefined,
          risk: undefined,
          countriesCode: undefined,
          providers: undefined,
          locations: undefined,
        }),
      );
    },
    onQueryChange: onSearchChange,
    onQueryClear: () => onSearchChange(''),
    onSort: (value: string[]) => {
      const data = value[0].split(' ');
      dispatch(
        visitorLogSlice.actions.handleVisitorLogTable({
          ...visitorLogTable,
          sortBy: data[0],
          sort: data[1],
        }),
      );
    },
    queryPlaceholder: 'Search by IP address/country/province/city/internet provider...',
    filters: Filters().visitors.filters,
    appliedFilters: Filters().visitors.appliedFilters.filter((item) => !item.hidden),
  };

  // Blocked
  const blockHistoryTable = useSelector(blockHistorySelector);
  const inputSearchBlocked = useSelector(inputSearchBlockedSelector);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchBlocked = useCallback(
    _debounce((value: ListBlock) => {
      dispatch(blockedVisitorSlice.actions.handleBlockHistoryTable(value));
    }, 1000),
    [],
  );
  const onSearchBlockedChange = (value: string) => {
    dispatch(blockedVisitorSlice.actions.handleInputSearch(value));
    debounceSetSearchBlocked({
      ...blockHistoryTable,
      search: value,
      page: 1,
    });
  };
  const blocked = {
    sortOptions: [
      { label: 'Total access', value: 'totalAccess asc', directionLabel: 'Ascending' },
      { label: 'Total access', value: 'totalAccess desc', directionLabel: 'Descending' },
      { label: 'Last seen on', value: 'lastSeenOn asc', directionLabel: 'Ascending' },
      { label: 'Last seen on', value: 'lastSeenOn desc', directionLabel: 'Descending' },
    ],
    sortSelected: [`${blockHistoryTable.sortBy} ${blockHistoryTable.sort}`],
    queryValue: inputSearchBlocked,
    onClearAll: () => {
      dispatch(visitorLogSlice.actions.handlePerPage('10'));
      dispatch(
        blockedVisitorSlice.actions.handleBlockHistoryTable({
          ...blockHistoryTable,
          risk: undefined,
          countriesCode: undefined,
          providers: undefined,
          locations: undefined,
        }),
      );
    },
    onQueryChange: onSearchBlockedChange,
    onQueryClear: () => onSearchBlockedChange(''),
    onSort: (value: string[]) => {
      const data = value[0].split(' ');
      dispatch(
        blockedVisitorSlice.actions.handleBlockHistoryTable({
          ...visitorLogTable,
          sortBy: data[0],
          sort: data[1],
        }),
      );
    },
    queryPlaceholder: 'Search by IP address/country/province/city/internet provider...',
    filters: Filters().Blocked.filters,
    appliedFilters: Filters().Blocked.appliedFilters.filter((item) => !item.hidden),
  };

  return {
    visitorLog,
    blocked,
  };
};

export default useConfigTable;
