import { colors } from '@/constants';
import styled from 'styled-components';

export const TopFiveListStyled = styled.div`
  .top5-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 6px 0px;
    border-radius: 8px;
    transition: 0.25s all linear;
    .btn-add-new-rule {
      &:hover {
        background-color: ${colors.gray_background};
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .top5-item__left {
      width: 40%;
      display: flex;
      align-items: center;
      gap: 10px;

      .top5-item__icon {
        width: 50px;
        height: 50px;
        padding: 15px;
        flex-shrink: 0;
        border-radius: 100%;
        background: rgba(225, 227, 229, 1);
        display: flex;
        align-items: center;
      }
      .top5-item__name {
        display: flex;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .top5-item__right {
      flex: 1;
      position: relative;
      .Polaris-ProgressBar__Indicator {
        /* background: linear-gradient(to right, #774ff3, #3d7cc9); */
      }

      .top5-item__percent {
        position: absolute;
        left: 6px;
        top: -2px;
        font-weight: bold;
      }
    }
  }
  .hover-card {
    position: relative;
    overflow: hidden;
    .Polaris-Box {
      min-height: 270px;
    }
    .va-top-high-risk-title {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      height: 45px;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 11px;
  }

  .hover-card:hover .overlay {
    opacity: 1;
  }
`;
