import { images } from '@/asset';
import BoldText from '@/components/BoldText';
import CustomBadge from '@/components/CustomBadge';
import RegularText from '@/components/RegularText';
import { PATH } from '@/constants';
import { dateToTimeStamp } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import { allAccessSelector } from '@/redux/slice/visitorAnalytics.slice';
import {
  Badge,
  Button, Card,
  Icon, Image, InlineGrid, SkeletonDisplayText,
  Text,
  Tooltip
} from '@shopify/polaris';
import {
  AlertCircleIcon, AlertTriangleIcon, BugIcon, DisabledIcon, MobileIcon, PersonIcon
} from '@shopify/polaris-icons';
// import { BarChart, PolarisVizProvider } from '@shopify/polaris-viz';
import { memo, useMemo } from 'react';
import { HeatMapGrid } from 'react-grid-heatmap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AnalyticsOverviewStyled } from './styled';

const Analytics = () => {
  const navigate = useNavigate();
  const allAccess = useSelector(allAccessSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const { userPlanFree } = userPlans();
  const dataSettings = useSelector(dataSettingsSelector);
  const blockedIps = apiCaller.useFetchChartBlockQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  }, { skip: isSkip });

  const visitors = apiCaller.useFetchChartAnalyticsQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
  }, { skip: isSkip });
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Lưu vào sessionStorage để người dùng có thay đổi timezone trên thiết bị thì không bị vỡ UI
  sessionStorage.setItem('timezone', timezone);
  const storedTimezone = sessionStorage.getItem('timezone');
  const dailyVisitTrend = apiCaller.useDailyVisitTrendQuery({
    endDate: dateToTimeStamp(allAccess.endDate),
    startDate: dateToTimeStamp(allAccess.startDate),
    timezone: storedTimezone || '',
  }, { skip: isSkip })

  let maxValueVisitTrend = 0;
  let mostVisitDay = 0;
  let mostVisitTime = 0;
  if (dailyVisitTrend.data) {
    for (let day = 0; day < dailyVisitTrend.data?.data?.length; day++) {
      for (let hour = 0; hour < dailyVisitTrend.data?.data[day]?.length; hour++) {
        if (dailyVisitTrend.data?.data[day][hour] > maxValueVisitTrend) {
          maxValueVisitTrend = dailyVisitTrend.data?.data[day][hour];
          mostVisitDay = day;
          mostVisitTime = hour;
        }
      }
    }
  }
  const dailyVisitData = dailyVisitTrend.data?.data || new Array(7).fill(new Array(24).fill(0));

  const dataChart = useMemo(() => {
    const result = [
      {
        title: 'Total allowed visitors',
        value: visitors.data?.data.totalVisit,
        icon: PersonIcon,
      },
      {
        title: 'Total blocked visitors',
        value: blockedIps.data?.data.totalBlocked,
        icon: DisabledIcon,
      },
      {
        title: 'Total blocked VPN & Proxy',
        value: blockedIps.data?.data.totalVpn,
        icon: BugIcon,
      },
      {
        title: 'Low risk IP',
        value: visitors.data?.data.totalSafeIp,
        icon: MobileIcon,
      },
      {
        title: 'High risk IP',
        value: visitors.data?.data.totalRiskIp,
        icon: AlertTriangleIcon,
      },
      {
        title: 'Dangerous IP',
        value: visitors.data?.data.totalDangerousIp,
        icon: AlertCircleIcon,
      },
    ];
    return result;
  }, [blockedIps.data, visitors.data]);

  const xLabels = new Array(24).fill(0).map((_, i) => `${i}h`);
  const yLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  return (
    <AnalyticsOverviewStyled>
      <Card>
        <BoldText>Overview {userPlanFree && !dataSettings?.settings.enableVisitorAnalytics ? <Badge tone='magic'>Demo</Badge> : null}</BoldText>
        <div className="analytics-container mt-16">
          <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }} gap="400">
            {/* <Card>
              <div className="mb-16">
                <BoldText>Live Visitor Snapshot</BoldText>
              </div>

              <div className="chart_container">
                <PolarisVizProvider>
                  <BarChart showLegend={false} theme="Light" data={fakeDate} />
                </PolarisVizProvider>

                <div className="visitor_online">
                  <Text as="p" variant="heading2xl" fontWeight="medium">
                    810
                  </Text>
                  <RegularText>Visitor Online</RegularText>
                </div>

                <div className="new_and_returning">
                  <div className="new_visitor">
                    <Text as="p" variant="headingLg" fontWeight="medium">
                      600
                    </Text>
                    <Text as="p">New Visitor</Text>
                    <div className="new_visitor_bar"></div>
                  </div>
                  <div className="returning">
                    <Text as="p" variant="headingLg" fontWeight="medium">
                      277
                    </Text>
                    <Text as="p">Returning</Text>
                    <div className="returning_bar"></div>
                  </div>
                </div>
              </div>
            </Card> */}

            <Card>
              <div className="mb-16">
                <BoldText>Daily Visit Trends</BoldText>
              </div>
              <div className='daily-chart-container'>
                <div className='daily-chart'>
                  <HeatMapGrid
                    key={JSON.stringify(allAccess)}
                    data={dailyVisitData}
                    xLabels={xLabels}
                    yLabels={yLabels}
                    // Render cell with tooltip
                    cellRender={(x, y, value) => (
                      <Tooltip hoverDelay={400} content={`${yLabels[x]} - ${y}h: ${value} views`}>
                        <div
                          className='daily-trend-value-hover'
                        >
                          {value}
                        </div>
                      </Tooltip>
                    )}
                    xLabelsStyle={(index) => ({
                      color: index % 2 ? 'transparent' : '#777',
                      fontSize: '.8rem',
                    })}
                    yLabelsStyle={() => ({
                      color: '#777',
                      marginRight: '4px',
                    })}
                    cellStyle={(_x, _y, ratio) => ({
                      background: ratio ? `rgba(255, 165, 0, ${ratio + 0.1 || 0})` : 'rgba(247, 247, 247)',
                      fontSize: '.8rem',
                      color: `rgb(0, 0, 0, ${ratio ? ratio / 2 + 0.4 : 0})`,
                    })}
                    cellHeight="1.5rem"
                    xLabelsPos="bottom"
                    onClick={() => { }}
                    yLabelsPos="left"
                  // square
                  />
                </div>

                <div className='daily-chart-content'>
                  <div className='daily-chart-text'>
                    <Text as="h5" variant="heading2xl" fontWeight="regular" alignment="end">
                      {(mostVisitDay >= 0 && mostVisitDay <= 6)
                        ? `${['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][mostVisitDay]}`
                        : '---'}
                    </Text>
                    <Text as="p" variant="bodyMd">
                      Busiest Day
                    </Text>
                  </div>
                  <div className='daily-chart-text'>
                    <Text as="h5" variant="heading2xl" fontWeight="regular" alignment="end">
                      {mostVisitTime >= 0 ? `${mostVisitTime}h` : '---'}
                    </Text>
                    <Text as="p" variant="bodyMd">
                      Busiest Time
                    </Text>
                  </div>
                </div>
              </div>
            </Card>
          </InlineGrid>

          <InlineGrid columns={{ xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }} gap={'400'}>
            {dataChart.map((item, index) => {
              return (
                <div className='hover-card' key={index}>
                  <Card>
                    <div className="analytics-items-title">
                      <Icon source={item.icon} tone="base" />
                      <div className="d-flex">
                        <div className="mr-8">
                          <Text fontWeight="medium" as="h5" variant="headingSm">
                            {item.title}
                          </Text>
                        </div>
                        {dataSettings?.settings.enableVisitorAnalytics && userPlanFree && item.title !== 'Total allowed visitors' && item.title !== 'Total blocked visitors' && (
                          <CustomBadge tone="info">Paid plan</CustomBadge>
                        )}
                      </div>
                    </div>
                    <div className="mt-8">
                      {blockedIps.isFetching || visitors.isFetching ? (
                        <SkeletonDisplayText />
                      ) : (
                        <div>
                          {userPlanFree && dataSettings?.settings.enableVisitorAnalytics ? (
                            <div>
                              {item.title !== 'Total allowed visitors' && item.title !== 'Total blocked visitors' ? (
                                <div>
                                  <RegularText>
                                    <Text as="h5" variant="heading2xl" fontWeight="regular">
                                      <Image source={images.lockFreePlanVA} alt={'lock-image'}></Image>
                                    </Text>
                                    Upgrade to see this metric
                                  </RegularText>
                                  <div className="overlay">
                                    <Button onClick={() => navigate(PATH.PRICING_PLAN)}>Upgrade</Button>
                                  </div>
                                </div>
                              ) :
                                (
                                  <Text as="h5" variant="heading2xl" fontWeight="regular">
                                    {(item.value || 0) < 20000 ? item.value : `${item.value}+`}
                                  </Text>
                                )}
                            </div>
                          ) : userPlanFree && !dataSettings?.settings.enableVisitorAnalytics ? (
                            <Tooltip content="Demo data">
                              <div className='analytics-items-value'>
                                <Text as="h5" variant="heading2xl" fontWeight="regular">
                                  {item.value}
                                </Text>
                              </div>
                            </Tooltip>
                          ) : (
                            <Text as="h5" variant="heading2xl" fontWeight="regular">
                              {item.value}
                            </Text>
                          )}
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              );
            })}
          </InlineGrid>
        </div>
      </Card>
    </AnalyticsOverviewStyled>
  );
};

export default memo(Analytics);
