import CustomButton from '@/components/CustomButton';
import RegularText from '@/components/RegularText';
import { config } from '@/config';
import { PATH } from '@/constants';
import { dateToTimeStamp } from '@/helpers';
import userPlans from '@/hooks/userPlans';
import { apiCaller } from '@/redux/query';
import slice from '@/redux/slice';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { bannerVisitorAnalyticWarningTableSelector } from '@/redux/slice/banner.slice';
import blockedVisitorSlice, { modeFilterBlockTableSelector } from '@/redux/slice/blockedVisitor.slice';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
import visitorLogSlice, {
  allAccessSelector,
  modeVisitorLogTableSelector,
  tabsTableSelector
} from '@/redux/slice/visitorAnalytics.slice';
import { Badge, Banner, Card, IndexFilters, IndexFiltersMode, IndexFiltersProps, Link, Tooltip } from '@shopify/polaris';
import { ExportIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TableBlocked from './BlockedTable';
import useConfigTable from './ConfigTable';
import TableVisitorLog from './VisitorAnalytics';

const TableAnalytics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userPlanFree } = userPlans();
  const configTable = useConfigTable();
  const modeAllowedTable = useSelector(modeVisitorLogTableSelector);
  const modeBlockedTable = useSelector(modeFilterBlockTableSelector);
  const bannerWarningStatus = useSelector(bannerVisitorAnalyticWarningTableSelector);
  const isSkip = useSelector(isSkipApiSelector);
  const selected = useSelector(tabsTableSelector);
  const allAccess = useSelector(allAccessSelector);
  const getVisitorQuota = apiCaller.useGetCheckQuotaQuery(undefined, { skip: !userPlanFree || isSkip });
  const totalVisitor = getVisitorQuota.data?.totalVisitor ?? 0;
  const dataSettings = useSelector(dataSettingsSelector);
  const handleTabChange = useCallback(
    (selectedTabIndex: number) => dispatch(slice.visitorAnalyticsSlice.actions.handleTableTab(selectedTabIndex)),
    [dispatch],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabs = [
    {
      id: 'visitorLog',
      content: 'Allowed visitors',
      component: <TableVisitorLog />,
      exportUrl: `${process.env.REACT_APP_API_END_POINT}visitor/export?shop=${config.shop}&urlParams=${JSON.stringify(
        config.urlParams,
      )}&startDate=${dateToTimeStamp(allAccess.startDate)}&endDate=${dateToTimeStamp(allAccess.endDate)}`,
    },
    {
      id: 'blocked',
      content: 'Blocked visitors',
      component: <TableBlocked />,
      exportUrl: `${process.env.REACT_APP_API_END_POINT}visitor/block/export?shop=${config.shop}&urlParams=${JSON.stringify(
        config.urlParams,
      )}&startDate=${dateToTimeStamp(allAccess.startDate)}&endDate=${dateToTimeStamp(allAccess.endDate)}`,
    },
  ];

  const configFilter = useMemo(() => {
    const key = tabs[selected].id;
    const result = {
      ...configTable[key as keyof typeof configTable],
    };
    return result;
  }, [configTable, tabs, selected]);

  return (
    <div className="table-container">
      <Card padding={'100'}>
        <IndexFilters
          {...configFilter}
          sortOptions={configFilter.sortOptions as IndexFiltersProps['sortOptions']}
          cancelAction={{
            onAction: () => { },
          }}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          mode={selected === 0 ? modeAllowedTable : modeBlockedTable}
          setMode={(mode) => selected === 0 ? dispatch(visitorLogSlice.actions.handleMode(mode)) : dispatch(blockedVisitorSlice.actions.handleMode(mode))}
          canCreateNewView={false}
        />
        {userPlanFree && bannerWarningStatus && dataSettings && totalVisitor >= dataSettings.settings.user.numberVisitorLimit &&
          <Banner
            tone="warning"
            title=""
            onDismiss={() => {
              dispatch(slice.banner.actions.handleBannerVisitorAnalyticWarningTable(false));
            }}
          >
            <RegularText>
              Visitor data has been paused due to Free plan limitation ({totalVisitor}/{dataSettings.settings.user.numberVisitorLimit} records). <Link onClick={() => navigate(PATH.PRICING_PLAN)}>Increase limit</Link> to continue record visitor data.
            </RegularText>
          </Banner>
        }
        <div className="export-btn" style={{ display: modeAllowedTable === IndexFiltersMode.Default || modeBlockedTable === IndexFiltersMode.Default ? 'block' : 'none' }}>
          <Tooltip content={'Export csv'}>
            <CustomButton icon={ExportIcon} url={tabs[selected].exportUrl} target="_blank" />
          </Tooltip>
        </div>
        {tabs[selected].component}
        {
          userPlanFree && !dataSettings?.settings.enableVisitorAnalytics &&
          <div className='badge-demo'>
            <Badge tone='magic'>Demo</Badge>
          </div>
        }
      </Card>
    </div>
  );
};

export default memo(TableAnalytics);
