import { Badge, Card, Tabs } from '@shopify/polaris';
import { memo, useCallback, useState } from 'react';
import HeatMapBlocked from './Blocked';
import HeatMapVisited from './Visited';
import { HeatMapStyled } from './styled';
import userPlans from '@/hooks/userPlans';
import { useSelector } from 'react-redux';
import { dataSettingsSelector } from '@/redux/slice/dataSettings.slice';
const HeatMap = () => {
  const { userPlanFree } = userPlans();
  const dataSettings = useSelector(dataSettingsSelector);
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => setSelected(selectedTabIndex), []);

  const tabs = [
    {
      id: 'visited',
      content: 'Allowed visitor heatmap',
      component: <HeatMapVisited />,
    },
    {
      id: 'blocked',
      content: 'Blocked visitor heatmap',
      component: <HeatMapBlocked />,
    },
  ];
  return (
    <HeatMapStyled>
      <Card padding={'100'}>
        <div className='card-container'>
          <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
            {tabs[selected].component}
          </Tabs>
          {
            userPlanFree && !dataSettings?.settings.enableVisitorAnalytics &&
            <div className='badge-demo'>
              <Badge tone='magic'>Demo</Badge>
            </div>
          }
        </div>
      </Card>
    </HeatMapStyled>
  );
};
export default memo(HeatMap);
